<template>
  <b-card-code>
    <validation-observer ref="simpleRules">
      <b-form @submit.prevent="submitForm">
        <b-row>
          <b-col md="6">
            <b-form-group>
              <label>Date</label>
              <label style="color: red !important">*</label>
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Date"
              >
                <flat-pickr
                  v-model="date"
                  class="form-control"
                  :config="{
                    enableTime: false,
                    dateFormat: 'd/m/Y',
                  }"
                  style="background: transparent"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group>
              <label>Employee Name</label>
              <label style="color: red !important">*</label>
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Employee Name"
              >
                <v-select
                  v-model="employee_id"
                  label="fullname"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  placeholder="None"
                  :options="employeeoption"
                >
              <template #option="{ fullname, profile_image, username, color }">
                    <b-avatar  :src="getprofileImage(profile_image)" />
                    <span :class="'font-weight-bolder text-'+color "> {{fullname}}  ({{ username }})</span
                    >
                  </template>
              </v-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group>
              <label>Basic + DA</label>
              <label style="color: red !important">*</label>
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Basic + DA"
              >
                <b-form-input
                  v-model="basicda"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  type="number"
                  placeholder="Enter Basic + DA"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group>
              <label>HRA</label>
              <label style="color: red !important">*</label>
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="HRA"
              >
                <b-form-input
                  v-model="hra"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  type="number"
                  placeholder="Enter HRA"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group>
              <label>Conveyance</label>
              <label style="color: red !important">*</label>
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Conveyance"
              >
                <b-form-input
                  v-model="conveyance"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  type="number"
                  placeholder="Enter Conveyance "
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group>
              <label>Medical Allowance</label>
              <label style="color: red !important">*</label>
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Medical Allowance"
              >
                <b-form-input
                  v-model="medicalallowance"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  type="number"
                  placeholder="Enter Medical Allowance"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group>
              <label>Education Allowance</label>
              <label style="color: red !important">*</label>
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Education Allowance"
              >
                <b-form-input
                  v-model="educationallowance"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  type="number"
                  placeholder="Enter Education Allowance"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group>
              <label>City Compensatory Allowance(CCA)</label>
              <label style="color: red !important">*</label>
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="City Compensatory Allowance(CCA)"
              >
                <b-form-input
                  v-model="cca"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  type="number"
                  placeholder="City Compensatory Allowance"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group>
              <label>Leave Travel Allowance (LTA)</label>
              <label style="color: red !important">*</label>
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Leave Travel Allowance"
              >
                <b-form-input
                  v-model="lta"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  type="number"
                  placeholder="Enter Leave Travel Allowance"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group>
              <label>Special Allowance</label>
              <label style="color: red !important">*</label>
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Special Allowance"
              >
                <b-form-input
                  v-model="specialallowance"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  type="number"
                  placeholder="Enter Special Allowance"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group>
              <label>Gross</label>
              <label style="color: red !important">*</label>
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Gross"
              >
                <b-form-input
                  v-model="gross"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  type="number"
                  placeholder="Enter Gross"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group>
              <label>PT</label>
              <label style="color: red !important">*</label>
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="PT"
              >
                <v-select
                  v-model="pt"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="name"
                  placeholder="None"
                  :options="['Yes', 'No']"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group>
              <label>PF</label>
              <label style="color: red !important">*</label>
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="PF"
              >
                <v-select
                  v-model="pf"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="name"
                  placeholder="None"
                  :options="['Yes', 'No']"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group>
              <label>ESIC</label>
              <label style="color: red !important">*</label>
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="ESIC"
              >
                <v-select
                  v-model="esic"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="name"
                  placeholder="None"
                  :options="['Yes', 'No']"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group>
              <label>Effective Date</label>
              <label style="color: red !important">*</label>
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Effective Date"
              >
                <flat-pickr
                  v-model="effectivedate"
                  placeholder="Select Date"
                  class="form-control"
                  :config="{
                    enableTime: false,
                    dateFormat: 'd/m/Y',
                  }"
                  style="background: transparent"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-button
              variant="primary"
              type="submit"
              @click.prevent="submitForm($event)"
              :disabled="flag"
              class="mr-4"
            >
              Submit
            </b-button>
            <b-button variant="primary" @click="onClickBack">Back </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>

    <!-- <template #code>
      {{ codeType }}
    </template> -->
  </b-card-code>
</template>

<script>
import BCardCode from "@core/components/b-card-code";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import PinchScrollZoom from "@coddicat/vue-pinch-scroll-zoom";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import {
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BInputGroupAppend,
  BCol,
  BInputGroup,
  BButton,
  BFormTextarea,
  BFormDatepicker,
  BImgLazy,
  BAvatar
} from "bootstrap-vue";
import {
  required,
  email,
  confirmed,
  url,
  between,
  alpha,
  integer,
  password,
  min,
  digits,
  alphaDash,
  length,
  min_value,
} from "@validations";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import axios from '@/components/axios';
import moment from "moment";

export default {
  components: {
    PinchScrollZoom,
    flatPickr,
    BCardCode,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    vSelect,
    BFormDatepicker,
    BFormTextarea,
    BImgLazy,
    BAvatar

  },
  data() {
    return {
      accessToken: localStorage.getItem("accessToken"),
      baseApi: process.env.VUE_APP_APIENDPOINT,
      date: moment(new Date()).format("DD/MM/yyyy"),
      // nextfollowupdate: moment(new Date()).format("DD/MM/yyyy"),
      employee_id: "",
      employeeoption: [],
      basicda: "",
      hra: "",
      conveyance: "",
      medicalallowance: "",
      educationallowance: "",
      cca: "",
      lta: "",
      specialallowance: "",
      gross: "",
      pt: "",
      pf: "",
      esic: "",
      effectivedate: moment(new Date()).format("DD/MM/yyyy"),
      // nextfollowupdate: moment(new Date()).format("DD/MM/yyyy"),
      ifEdit: "",
      accessToken: localStorage.getItem("accessToken"),
      baseApi: process.env.VUE_APP_APIENDPOINT,
      id: "",
      flag: false,
      imagePath: process.env.VUE_APP_IMAGE_PATH,

    };
  },
  mounted() {
    const userData = JSON.parse(localStorage.getItem("UserRights"));
    const role = JSON.parse(localStorage.getItem("userData")).role;
    if (role !== "admin") {
      userData.map((item) => {
        if (item.modulename == "Salary") {
          if (this.$route.params.id) {
            if (item.edit !== 1) {
              this.$router.push("/hr/salary");
            }
          } else if (item.add !== 1) {
            this.$router.push("/hr/salary");
          }
        }
      });
    }
    this.init();
    this.ifEdit = !!this.$route.params.id;
    this.ifEdit && this.getEditValue();
  },
  methods: {
     getprofileImage(image) {
      if (image !== undefined && image !== null && image !== "") {
        let test = image.includes(this.imagePath)
          ? image
          : this.imagePath + image;
        return test;
      }
      return image;
    },
    async getEditValue() {
      await axios({
        method: "GET",
        url: `${this.baseApi}/getsalaryById/${this.$route.params.id}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
      })
        .then((json) => {
          // if (json.data.length) {
          // }
          this.getEditItems(json.data.data);
        })
        .catch((error) => console.log(error, "error"));
    },
    getEditItems(item) {
      item.map((item) => {
        this.date = moment(item.date).format("DD/MM/yyyy");
        this.employee_id = this.getUser(item.employee_id);
        this.basicda = item.basicda;
        this.hra = item.hra;
        this.conveyance = item.conveyance;
        this.medicalallowance = item.medicalallowance;
        this.educationallowance = item.educationallowance;
        this.cca = item.cca;
        this.lta = item.lta;
        this.specialallowance = item.specialallowance;
        this.gross = item.gross;
        this.pt = item.pt;
        this.pf = item.pf;
        this.esic = item.esic;
        this.effectivedate = moment(item.effectivedate).format("DD/MM/yyyy");
      });
    },
    init() {
      this.getUser();
    },
    async getUser(id) {
      if (id !== undefined) {
        this.id = id;
      }
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/getEmployee`,
      };
      await axios(requestOptions)
        .then((response) => {
          this.employeeoption = response.data.data;
          this.employeeoption.map((item) => {
            if (item.id == id) {
              this.employee_id = item;
            }
          });
        })
        .catch((error) => console.log(error, "error"));
    },
    onClickBack() {
      this.$router.push("/hr/salary");
    },
    submitForm(e) {
      const data = {
        date: this.date,
        employee_id: this.employee_id.id,
        basicda: this.basicda,
        hra: this.hra,
        conveyance: this.conveyance,
        medicalallowance: this.medicalallowance,
        educationallowance: this.educationallowance,
        cca: this.cca,
        lta: this.lta,
        specialallowance: this.specialallowance,
        gross: this.gross,
        pt: this.pt,
        pf: this.pf,
        esic: this.esic,
        effectivedate: this.effectivedate,
      };
      // var ifEdit = this.$route.params.id ? true : false;
      this.$refs.simpleRules.validate().then(async (success) => {
        if (success == false) {
          this.$swal({
            title: "Warning",
            timer: 5000,
            text: "Plese Fill the All Field",
            icon: "warning",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        }
        if (success) {
          this.flag = true;
          const accessToken = localStorage.getItem("accessToken");
          const baseApi = process.env.VUE_APP_APIENDPOINT;
          await axios({
            method: `${this.ifEdit ? "put" : "post"}`,
            url: this.ifEdit
              ? `${baseApi}/salary/${this.$route.params.id}`
              : `${baseApi}/salary`,
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${accessToken}`,
            },
            data: JSON.stringify(data),
          })
            .then((response) => {
              this.flag = false;

              this.$router.push("/hr/salary");
              this.$swal({
                title: "Submited",
                text: response.data.message
                  ? `${response.data.message}`
                  : response.data.success
                  ? `${response.data.success}`
                  : "Update SuccessFully",
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
            })
            .catch((error) => {
              this.flag = false;

              this.$swal({
                title: "Error!",
                text: `${error}`,
                icon: "error",
                timer: 5000,
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    },
  },
};
</script>
